<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .header-slideshow-outer {
    position: relative;
    width: 100%;
    height: 700px;
    z-index: -2;
    margin-top: 30px;

    @media (min-width: 2000px) {
      height: 800px;
    }

    @media (min-width: 3000px) {
      height: 900px;
    }

    @media (max-width: 1600px) {
      height: 600px;
    }

    @media (max-width: 1200px) {
      height: 500px;
    }

    @media (max-width: $width-lg) {
      height: 400px;
    }

    @media (max-width: $width-md) {
      height: 350px;
    }
  }

  .header-image-slideshow {
    background: no-repeat 50% 50%;
    background-size: cover;
    background-image: none;

    //$items: 2;
    // $animation-time: 10s;
    //$transition-time: 10.5s;
    //$scale: 20%;

    //$total-time: ($animation-time * $items);
    //$scale-base-1: (1 + $scale / 100%);
    position: absolute;
    width: 100%;
    height: 700px;
    overflow: hidden;

    @media (min-width: 2000px) {
      height: 800px;
    }

    @media (min-width: 3000px) {
      height: 900px;
    }

    @media (max-width: 1600px) {
      height: 600px;
    }

    @media (max-width: 1200px) {
      height: 500px;
    }

    @media (max-width: $width-lg) {
      height: 400px;
    }

    @media (max-width: $width-md) {
      height: 350px;
    }

    .slideshow-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background: no-repeat 50% 50%;
      background-size: cover;
      animation-name: kenburns;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: 16s;
      opacity: 1;
      transform: scale(1.2);
    }

    .slideshow-image:nth-child(1) {
      animation-name: kenburns-1;
      z-index: 3;
    }

    .slideshow-image:nth-child(2) {
      animation-name: kenburns-2;
      z-index: 2;
    }

    .slideshow-image:nth-child(3) {
      animation-name: kenburns-3;
      z-index: 1;
    }

    .slideshow-image:nth-child(4) {
      animation-name: kenburns-4;
      z-index: 0;
    }

    @keyframes kenburns-1 {
      0% {
        opacity: 1;
        transform: scale(1.2);
      }

      1.5625% {
        opacity: 1;
      }

      23.4375% {
        opacity: 1;
      }

      26.5625% {
        opacity: 0;
        transform: scale(1);
      }

      100% {
        opacity: 0;
        transform: scale(1.2);
      }

      98.4375% {
        opacity: 0;
        transform: scale(1.21176);
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes kenburns-2 {
      23.4375% {
        opacity: 1;
        transform: scale(1.2);
      }

      26.5625% {
        opacity: 1;
      }

      48.4375% {
        opacity: 1;
      }

      51.5625% {
        opacity: 0;
        transform: scale(1);
      }

      100% {
        opacity: 0;
        transform: scale(1.2);
      }
    }

    @-webkit-keyframes kenburns-3 {
      48.4375% {
        opacity: 1;
        transform: scale(1.2);
      }

      51.5625% {
        opacity: 1;
      }

      73.4375% {
        opacity: 1;
      }

      76.5625% {
        opacity: 0;
        transform: scale(1);
      }

      100% {
        opacity: 0;
        transform: scale(1.2);
      }
    }

    @-webkit-keyframes kenburns-4 {
      73.4375% {
        opacity: 1;
        transform: scale(1.2);
      }

      76.5625% {
        opacity: 1;
      }

      98.4375% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: scale(1);
      }
    }
  }

  .digital-marketplaces-overview {
    position: relative;

    .img-logo-overview {
      border-bottom: 1px solid #000;

      img {
        max-width: 500px;
        margin: 40px auto 50px auto;
        display: block;
      }
    }

    .intro-boxen {
      border: 0px solid red;

      .txt-box {
        h2 {
          margin-bottom: 20px;
        }

        padding: 0px 30px;
      }

      .img-box {
        height: 500px;
        background-size: cover;
        background-position: center center;
        max-width: 90%;
        margin: 20px auto;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;

          top: 20px;
          position: absolute;
          z-index: -1;
        }


      }

      .img-bg-ueber-uns {
        &:after {
          left: -20px;
          background: $coral;
        }
      }

      .img-box-startup {
        &:after {
          left: 20px;
          background: $mint;
        }
      }

      .img-bg-karriere {
        &:after {
          left: -20px;
          background: $violet-50per;
        }
      }

      .img-box-slogan {
        padding: 20px;
        position: absolute;
        left: -100px;
        bottom: -100px;
        z-index: -1;

        @media (max-width: $width-lg) {
          position: relative;
          margin: 0 auto;
          left: 0;
          bottom: 0;
        }

        img {
          display: block;
          margin: 0 auto;
          max-width: 200px;
          width: 100%;
          animation: slogan-rotating 15s linear infinite;
        }

        @keyframes slogan-rotating {
          from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }

          to {
            -ms-transform: rotate(-360deg);
            -moz-transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
            -o-transform: rotate(-360deg);
            transform: rotate(-360deg);
          }
        }
      }
    }

    .start-outer {
      padding-bottom: 30px;
      padding-top: 30px;

      .img-box-start {
        width: 100%;
        height: 600px;
        background-size: cover;
        background-position: center center;

        @media (max-width: $width-lg) {
          margin: 0 auto 30px auto;
        }
      }
    }

    .btn-std {
      text-align: left;
      margin: 30px 0;
    }

    .btn-center {
      text-align: center;
    }

    .marken-slider-outer {

      //border:1px solid red;
      .slideritem {
        padding: 20px;

        //border:1px solid red;
        a {
          display: block;
          height: 300px;

          //border:1px solid green;
          img {
            position: relative;
            max-width: 250px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="test Styria Digital Marketplaces" />-->
  <div class="header-slideshow-outer">
    <div class="header-image-slideshow">

      <div class="slideshow-image"
        :style="`background-image: url(${image.name}); background-position:${image.position}`" :key="image"
        v-for="image of slideShowImages"></div>
      <!-- // <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/header-img-v6.jpg')})` }" style="background-position:top center;"></div>
      // <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/header-img-v7.jpg')})` }" style="background-position:center center;"></div>
      // <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/header-img-v8.jpg')})` }" style="background-position:top center;"></div> -->
    </div>
  </div>
  <div class="digital-marketplaces-overview">
    <div class="container-fluid  img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="container intro-boxen">
      <div class="row justify-content-center align-self-center padding-tb-s">
        <div class="col-lg-6 align-self-center order-lg-2">
          <div class="txt-box">
            <h2>{{ $t('marketplaces-uebersicht.ueber-uns') }}</h2>
            <p>{{ $t('marketplaces-uebersicht.ueber-uns-txt') }}</p>
            <div class="btn-std">
              <router-link :to="{ name: 'marketplaces-ueber-uns' }"><span class="txt">{{
                  $t('marketplaces-uebersicht.ueber-uns-btn')
              }}</span> <span class="icon-img"><img
                    src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 align-self-center position-relative">
          <div class="img-box img-bg-ueber-uns"
            :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/ueber-uns.jpg')})` }"></div>
          <div class="img-box-slogan"><img src="@/assets/img/ueber-uns/slogan-one-spirit-coral.svg"
              alt="One spirit - unlimited ideas" class="img-fluid" /></div>
        </div>
      </div>
    </div>
    <div class="container newsbox-frontend">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.brands') }}</span></div>
              <h1 v-html="$t('General.news')"></h1>
            </div>
          </div>
        </div>
      </div>


      <div class="row justify-content-center align-self-center">
        <EntryIndex :category="3" :limit="10" slider showerror routename="marketplacesnewsdetail" />

        <div class="row">
          <div class="col-12 padding-t-m">
            <div class="btn-std btn-center">
              <router-link :to="{ name: 'marketplacesnews' }"><span
                  class="txt">{{ $t('marketplaces-uebersicht.mehr-news-zu-marketplaces') }}</span> <span
                  class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts"
                    class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey">
      <div class="container intro-boxen margin-b-s">
        <div class="row justify-content-center align-self-center padding-tb-s">
          <div class="col-lg-6 align-self-center">
            <div class="txt-box text-center">
              <h2>{{ $t('marketplaces-uebersicht.marken') }}</h2>
              <p>{{ $t('marketplaces-uebersicht.marken-intro-txt') }}</p>
              <div class="btn-std text-center">
                <router-link :to="{ name: 'marketplaces-marken' }"><span class="txt">{{
                    $t('marketplaces-uebersicht.marken-btn')
                }}</span> <span class="icon-img"><img
                      src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid" /></span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <!-- -->
            <div class="marken-slider-outer">
              <Slider :delay="3500" :items="1" easing="linear" autoplay :speed="1000">
                <!--<template #navigation="{ left, right }">
              <div @click="left">LEFT</div>
              <div @click="right">RIGHT</div>
            </template>-->
                <template #items>
                  <SliderItem>
                    <router-link :to="{ name: 'willhaben' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/willhaben.svg" alt="Willhaben" class="img-fluid" />
                    </router-link>
                  </SliderItem>
                  <SliderItem>
                    <router-link :to="{ name: 'njuskalo' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/njuskalo.svg" alt="Njuskalo" class="img-fluid" />
                    </router-link>
                  </SliderItem>
                  <SliderItem>
                    <router-link :to="{ name: 'bolha' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/bolha.svg" alt="bolha.com" class="img-fluid" />
                    </router-link>
                  </SliderItem>
                  <SliderItem>
                    <router-link :to="{ name: 'mojedelo' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/mojedelo.svg" alt="Moje Delo" class="img-fluid" />
                    </router-link>
                  </SliderItem>
                  <SliderItem>
                    <router-link :to="{ name: 'njuskalo-technology' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/njuskalo-technology.png" alt="Njuskalo Technology"
                        class="img-fluid" />
                    </router-link>
                  </SliderItem>
                </template>
              </Slider>
            </div>
            <!-- -->
            <!--<div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }"></div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="container intro-boxen">
      <!---->
      <div class="row justify-content-center align-self-center padding-tb-s">
        <div class="col-lg-6 align-self-center">
          <div class="txt-box">
            <h2>Start-ups &amp; Ventures</h2>
            <p>{{ $t('marketplaces-uebersicht.startup-ventures-txt') }}</p>
            <div class="btn-std">
              <router-link :to="{ name: 'marketplaces-startup-ventures' }"><span class="txt">{{
                  $t('marketplaces-uebersicht.startup-ventures-btn')
              }}</span> <span class="icon-img"><img
                    src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 align-self-center">
          <div class="img-box img-box-startup"
            :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/startup-ventures.jpg')})` }"></div>
        </div>
      </div>
      <!---->
      <div class="row justify-content-center align-self-center padding-tb-s">
        <div class="col-lg-6 align-self-center order-lg-2">
          <div class="txt-box">
            <h2>{{ $t('marketplaces-uebersicht.karrire-hl') }}</h2>
            <p>{{ $t('marketplaces-uebersicht.karrire-txt') }}</p>
            <div class="btn-std">
              <router-link :to="{ name: 'marketplaces-karriere' }"><span class="txt">{{
                  $t('marketplaces-uebersicht.karriere-btn')
              }}</span> <span class="icon-img"><img
                    src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 align-self-center">
          <div class="img-box img-bg-karriere"
            :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/karriere-marketplaces.jpg')})` }">
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import Slider from '../../../components/Slider.vue';
import SliderItem from '../../../components/SliderItem.vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
//import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';
export default defineComponent({
  meta: {
    de: {
      title: 'Marketplaces | Styria Media Group',
      keywords: ['marktplatz', 'kleinanzeigen', 'willhaben', 'njuskalo', 'bolha.com', 'bestby', 'styria'],
      description: 'Zu den Styria Digital Marketplaces gehören Kleinanzeigenplattformen und Stellenbörsen wie willhaben, Njuškalo.hr, bolha.com und MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Marketplaces | Styria Media Group',
      keywords: ['marketplaces', 'kleinanzeigen', 'willhaben', 'njuskalo', 'bolha.com', 'bestby', 'styria'],
      description: 'Styria Digital Marketplaces include classified ad platforms and job boards such as willhaben, Njuškalo.hr, bolha.com and MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Slider,
    SliderItem,
    EntryIndex,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    const slideShowImages = ref<Array<{ name: string, position: string }>>([
      { name: require('@/assets/img/marketplaces/header-img-v3.jpg'), position: "center center" },
      { name: require('@/assets/img/marketplaces/header-img-v6.jpg'), position: "top center" },
      { name: require('@/assets/img/marketplaces/header-img-v7.jpg'), position: "center center" },
      { name: require('@/assets/img/marketplaces/header-img-v8.jpg'), position: "top center" }]);

    onMounted(() => {
      slideShowImages.value.sort((a, b) => 0.5 - Math.random());
    });

    return {
      slideShowImages,
      mdiChevronUp,
    };
  },
});
</script>
